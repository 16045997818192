import * as React from 'react';
import styled from '@emotion/styled';
import { SocialLink } from '../../styles/shared';
import Facebook from './facebook';
import Instagram from './instagram';
import Linkedin from './linkedin';
import Strava from './strava';
import Twitter from './twitter';
import { WebsiteConfig } from '../../website-config';
import Github from './github';
import Goodreads from './goodreads';

const SocialLinksContainer = styled.div`
  flex-shrink: 0;
  display: flex;
  align-items: center;
  a:last-of-type {
    padding-right: 10px;
  }
`;

export interface SocialLinksProps {
  config: WebsiteConfig;
}
const SocialLinks: React.FC<SocialLinksProps> = ({ config }) => {
  return (
    <SocialLinksContainer>
      {config.github && (
        <a
          css={SocialLink}
          href={config.github}
          title="Github"
          target="_blank"
          rel="noopener noreferrer"
        >
          <Github />
        </a>
      )}
      {config.linkedin && (
        <a
          css={SocialLink}
          href={config.linkedin}
          title="Linkedin"
          target="_blank"
          rel="noopener noreferrer"
        >
          <Linkedin />
        </a>
      )}
      {config.facebook && (
        <a
          css={SocialLink}
          href={config.facebook}
          target="_blank"
          title="Facebook"
          rel="noopener noreferrer"
        >
          <Facebook />
        </a>
      )}
      {config.instagram && (
        <a
          css={SocialLink}
          href={config.instagram}
          title="Instagram"
          target="_blank"
          rel="noopener noreferrer"
        >
          <Instagram />
        </a>
      )}
      {config.goodreads && (
        <a
          css={SocialLink}
          href={config.goodreads}
          title="Goodreads"
          target="_blank"
          rel="noopener noreferrer"
        >
          <Goodreads />
        </a>
      )}
      {config.strava && (
        <a
          css={SocialLink}
          href={config.strava}
          title="Strava"
          target="_blank"
          rel="noopener noreferrer"
        >
          <Strava />
        </a>
      )}
      {config.twitter && (
        <a
          css={SocialLink}
          href={config.twitter}
          title="Twitter"
          target="_blank"
          rel="noopener noreferrer"
        >
          <Twitter />
        </a>
      )}
    </SocialLinksContainer>
  );
};

export default SocialLinks;
