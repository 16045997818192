// tslint:disable:no-http-string
import * as React from 'react';

const Instagram: React.FC = () => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    version="1.1"
    baseProfile="full"
    width="24"
    height="24"
    viewBox="0 0 24.00 24.00"
    enableBackground="new 0 0 24.00 24.00"
  >
    <path
      fill="#ffffff"
      fillOpacity="1"
      strokeLinejoin="round"
      d="M 7.8,2.00002L 16.2,2.00002C 19.4032,2.00002 22,4.59677 22,7.80002L 22,16.2C 22,19.4033 19.4032,22 16.2,22L 7.8,22C 4.59675,22 2,19.4033 2,16.2L 2,7.80002C 2,4.59677 4.59675,2.00002 7.8,2.00002 Z M 7.6,4C 5.61177,4 4,5.61178 4,7.6L 4,16.4C 4,18.3882 5.61177,20 7.6,20L 16.4,20C 18.3882,20 20,18.3882 20,16.4L 20,7.6C 20,5.61178 18.3882,4 16.4,4L 7.6,4 Z M 17.25,5.5C 17.9404,5.5 18.5,6.05965 18.5,6.75C 18.5,7.44036 17.9404,8 17.25,8C 16.5596,8 16,7.44036 16,6.75C 16,6.05965 16.5596,5.5 17.25,5.5 Z M 12,7C 14.7614,7 17,9.23858 17,12C 17,14.7614 14.7614,17 12,17C 9.23857,17 7,14.7614 7,12C 7,9.23858 9.23857,7 12,7 Z M 12,9C 10.3431,9 9,10.3432 9,12C 9,13.6569 10.3431,15 12,15C 13.6569,15 15,13.6569 15,12C 15,10.3432 13.6569,9 12,9 Z "
    />
  </svg>
);
export default Instagram;
