// tslint:disable:no-http-string
import * as React from 'react';

const Goodreads: React.FC = () => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    version="1.1"
    baseProfile="full"
    width="24"
    height="24"
    viewBox="0 0 24.00 24.00"
    enableBackground="new 0 0 24.00 24.00"
  >
    <path
      fill="#ffffff"
      fillOpacity="1"
      strokeWidth="2"
      strokeLinejoin="miter"
      d="M 17,12.7732C 15.9644,14.4195 14.2233,15.5 12.25,15.5C 9.07436,15.5 6.5,12.7018 6.5,9.25001C 6.5,5.79823 9.07436,3.00001 12.25,3.00001C 14.2233,3.00001 15.9644,4.08046 17,5.7268L 17,3L 18,3L 18,15.25C 18,20.7087 14.5375,21 11.5,21C 8.98243,21 7.54687,19.3085 7.12914,17L 8.14065,17C 8.49534,18.7478 9.54066,20 11.5,20C 13.7199,20 17,20.0525 17,15.25L 17,15.25L 17,12.7732 Z M 12.25,4.00001C 9.62665,4.00001 7.5,6.35052 7.5,9.25001C 7.5,12.1495 9.62664,14.5 12.25,14.5C 14.8733,14.5 17,12.1495 17,9.25001C 17,6.35052 14.8733,4.00001 12.25,4.00001 Z "
    />
  </svg>
);
export default Goodreads;
