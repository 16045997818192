// tslint:disable:no-http-string
import * as React from 'react';

const Strava: React.FC = () => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    version="1.1"
    baseProfile="full"
    width="24"
    height="24"
    viewBox="0 0 24.00 24.00"
    enableBackground="new 0 0 24.00 24.00"
  >
    <path
      fill="#ffffff"
      fillOpacity="1"
      strokeWidth="1.33333"
      strokeLinejoin="miter"
      d="M 14.923,17.1614L 16.7474,13.527L 19.4546,13.527L 14.9362,22.5L 10.3709,13.527L 13.0734,13.527L 14.923,17.1614 Z M 10.6276,8.66043L 8.17542,13.5452L 4.54537,13.5452L 10.6096,1.49996L 16.7355,13.5452L 13.111,13.5452L 10.6276,8.66043 Z "
    />
  </svg>
);
export default Strava;
