// tslint:disable:no-http-string
import * as React from 'react';

const Linkedin: React.FC = () => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    version="1.1"
    baseProfile="full"
    width="24"
    height="24"
    viewBox="0 0 24.00 24.00"
    enableBackground="new 0 0 24.00 24.00"
  >
    <path
      fill="#ffffff"
      fillOpacity="1"
      strokeWidth="0.2"
      strokeLinejoin="round"
      d="M 21,21L 17,21L 17,14.25C 17,13.1914 15.8121,12.3058 14.7535,12.3058C 13.6949,12.3058 13,13.1914 13,14.25L 13,21L 9,21L 9,9L 13,9L 13,11C 13.6623,9.92857 15.3564,9.23727 16.525,9.23727C 18.9965,9.23727 21,11.2786 21,13.75L 21,21 Z M 6.99999,21L 2.99999,21L 2.99999,9L 6.99999,9L 6.99999,21 Z M 5,3C 6.10457,3 7,3.89543 7,5C 7,6.10457 6.10457,7 5,7C 3.89543,7 3,6.10457 3,5C 3,3.89543 3.89543,3 5,3 Z "
    />
  </svg>
);
export default Linkedin;
